<script setup lang="ts">
import { getElementUiObject } from '@mop/cms/utils/utils';
import type { EditableCmsComponent } from '@/types/cms';

defineOptions({
  name: 'CmsCompanyAudio',
});

type CmsCompanyAudio = EditableCmsComponent & {
  title?: string;
  subTitle?: string;
  headline?: string;
  fileUrl?: string;
  captionsFile?: string;
  subtitlesFile?: string;
};

const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
});

const element: CmsCompanyAudio = getElementUiObject(props.data, {
  title: props.data.title,
  subTitle: props.data.subTitle,
  headline: props.data.headline,
  fileUrl: props.data.file?.filename,
  captionsFile: props.data.captionsFile,
  subtitlesFile: props.data.subtitlesFile,
});
</script>

<template>
  <Ui2CompanyAudio
    v-if="element.fileUrl"
    v-storyblok-editable="element"
    :title="element.title"
    :sub-title="element.subTitle"
    :headline="element.headline"
    :file-url="element.fileUrl"
    :captions-file="element.captionsFile"
    :subtitles-file="element.subtitlesFile"
  />
</template>
